<script setup>
import { useAttrs } from "vue";

const props = defineProps({
  selectionName: String,
  description: String,
  content: {
    type: Boolean,
    default: false,
  },
});

const $attrs = useAttrs();
</script>

<template>
  <div v-bind="$attrs" class="section-header">
    <h2 class="text-uppercase tw-text-xl lg:tw-text-2xl">
      {{ selectionName }}
    </h2>
    <p class="text-capitalize">
      {{ description || "" }}
    </p>
  </div>
  <div v-if="content" class="py-2 content">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.section-header {
  text-align: center;
  padding-bottom: 60px;

  h2 {
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 50px;
      height: 3px;
      background: $primary;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  p {
    font-size: 15px;
    margin-bottom: 0;
    color: $secondary;
  }
}
</style>
